<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs12>
            <v-layout wrap justify-start pa-5>
              <v-flex xs12>
                <v-layout wrap justify-start>
                  <v-flex xs12 lg8 md8 sm12>
                    <span class="heading">Snake Reports Map</span>
                  </v-flex>
                  <v-flex xs12 lg4 md4 sm12 class="text-end">
                    <span class="subheading"
                      >Total Records Found: {{ totalcount }}</span
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12>
                <span
                  style="
                    color: red;
                    font-family: poppinsregular;
                    font-size: 13px;
                  "
                  >Please select the main criterias for search.
                </span>
              </v-flex>
              <v-flex xs12>
                <v-layout wrap justify-start>
                  <v-flex xs12>
                    <v-card flat>
                      <v-layout wrap justify-center>
                        <v-flex xs12 sm12 lg12>
                          <v-layout wrap justify-start>
                            <v-flex xs10 sm8 md5 lg5>
                              <v-layout wrap>
                                <v-flex class="text-left subheading">
                                  <v-checkbox
                                    v-model="alldata"
                                    label="All"
                                    @change="checkUpdate()"
                                    class="small-checkbox no-space"
                                  ></v-checkbox>
                                </v-flex>
                                <v-flex class="text-left subheading">
                                  <v-checkbox
                                    v-model="timeperiod"
                                    label="Time Period"
                                    :disabled="alldata"
                                    class="small-checkbox no-space"
                                  ></v-checkbox>
                                </v-flex>
                                <v-flex class="text-left subheading">
                                  <v-checkbox
                                    v-model="bigsnakes"
                                    label="Big4 Snakes
"
                                    :disabled="alldata"
                                    class="small-checkbox no-space"
                                  ></v-checkbox>
                                </v-flex>
                                <v-flex class="text-left subheading">
                                  <v-checkbox
                                    v-model="allsnakes"
                                    label="All Snakes
"
                                    :disabled="alldata"
                                    class="small-checkbox no-space"
                                  ></v-checkbox>
                                </v-flex>
                                <v-flex class="text-left subheading">
                                  <v-checkbox
                                    v-model="users"
                                    label="User"
                                    :disabled="alldata"
                                    class="small-checkbox no-space"
                                  ></v-checkbox>
                                </v-flex>
                                <v-flex class="text-left subheading">
                                  <v-checkbox
                                    v-model="divisioncheck"
                                    label="Office"
                                    :disabled="alldata"
                                    class="small-checkbox no-space"
                                  ></v-checkbox>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                          <v-layout wrap justify-start pb-2>
                            <v-flex xs12>
                              <v-layout wrap justify-start>
                                <v-flex
                                  xs12
                                  sm4
                                  md4
                                  lg4
                                  class="subheading"
                                  v-if="timeperiod"
                                >
                                  <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <span>From Date</span>
                                      <v-text-field
                                        v-model="fromdate"
                                        label="Select"
                                        append-icon="mdi-calendar"
                                        readonly
                                        outlined
                                        dense
                                        hide-details="true"
                                        v-bind="attrs"
                                        v-on="on"
                                        :disabled="alldata"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="fromdate"
                                      :active-picker.sync="activePicker"
                                      :max="
                                        new Date(
                                          Date.now() -
                                            new Date().getTimezoneOffset() *
                                              60000
                                        )
                                          .toISOString()
                                          .substring(0, 10)
                                      "
                                      min="1950-01-01"
                                      @change="save"
                                    ></v-date-picker>
                                  </v-menu>
                                </v-flex>
                                <v-flex
                                  xs12
                                  sm4
                                  md4
                                  lg4
                                  pl-lg-2
                                  pl-sm-2
                                  pl-md-2
                                  class="subheading"
                                  v-if="timeperiod"
                                >
                                  <v-menu
                                    ref="menu2"
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <span>To Date</span>
                                      <v-text-field
                                        v-model="todate"
                                        label="Select"
                                        append-icon="mdi-calendar"
                                        readonly
                                        outlined
                                        dense
                                        hide-details="true"
                                        v-bind="attrs"
                                        v-on="on"
                                        :disabled="alldata"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="todate"
                                      :active-picker.sync="activePickerto"
                                      :max="
                                        new Date(
                                          Date.now() -
                                            new Date().getTimezoneOffset() *
                                              60000
                                        )
                                          .toISOString()
                                          .substring(0, 10)
                                      "
                                      min="1950-01-01"
                                      @change="saveto"
                                    ></v-date-picker>
                                  </v-menu>
                                </v-flex>
                                <v-flex
                                  xs12
                                  lg4
                                  sm4
                                  md4
                                  v-if="bigsnakes"
                                  class="subheading"
                                  :pl-lg-2="timeperiod ? true : false"
                                  :pl-md-2="timeperiod ? true : false"
                                  :pl-sm-2="timeperiod ? true : false"
                                >
                                  <span>Select Big4</span>
                                  <v-select
                                    :items="bigsnakestypes"
                                    v-model="bigsnakeselect"
                                    item-text="text"
                                    item-value="value"
                                    multiple
                                    outlined
                                    dense
                                    hide-details="true"
                                    :disabled="alldata"
                                  >
                                  </v-select>
                                </v-flex>
                                <v-flex
                                  xs12
                                  lg4
                                  sm4
                                  md4
                                  v-if="allsnakes"
                                  class="subheading"
                                >
                                  <span>Select Snakes</span>
                                  <v-select
                                    :items="snakeitems"
                                    v-model="allsnakelist"
                                    multiple
                                    item-text="name"
                                    item-value="_id"
                                    outlined
                                    dense
                                    hide-details="true"
                                    :disabled="alldata"
                                  >
                                  </v-select>
                                </v-flex>
                                <v-flex
                                  xs12
                                  lg4
                                  sm4
                                  md4
                                  v-if="users"
                                  class="subheading"
                                  :pl-lg-2="allsnakes ? true : false"
                                  :pl-md-2="allsnakes ? true : false"
                                  :pl-sm-2="allsnakes ? true : false"
                                >
                                  <span>Select User</span>
                                  <v-select
                                    :items="userslist"
                                    v-model="selecteduser"
                                    item-text="username"
                                    item-value="username"
                                    outlined
                                    :disabled="alldata"
                                    dense
                                    hide-details="true"
                                  >
                                  </v-select>
                                </v-flex>
                                <v-flex
                                  xs12
                                  lg4
                                  sm4
                                  md4
                                  :pl-lg-2="users ? true : false"
                                  :pl-md-2="users ? true : false"
                                  :pl-sm-2="users ? true : false"
                                  v-if="divisioncheck"
                                >
                                  <span class="subheading">Division</span>
                                  <v-autocomplete
                                    outlined
                                    label="Division Name"
                                    :items="divisionList"
                                    v-model="divisionname"
                                    item-text="divisionName"
                                    item-value="_id"
                                    dense
                                    hide-details="true"
                                    class="itemform"
                                  >
                                  </v-autocomplete>
                                </v-flex>
                                <v-flex
                                  xs12
                                  sm4
                                  md4
                                  lg4
                                  :pl-lg-2="divisioncheck ? true : false"
                                  :pl-md-2="divisioncheck ? true : false"
                                  :pl-sm-2="divisioncheck ? true : false"
                                  v-if="divisioncheck"
                                >
                                  <span class="subheading">Range</span>
                                  <v-autocomplete
                                    outlined
                                    label="Range Name"
                                    :items="rangeList"
                                    v-model="rangename"
                                    item-text="rangeName"
                                    item-value="_id"
                                    hide-details="true"
                                    class="itemform"
                                    dense
                                    :disabled="divisionname ? false : true"
                                  >
                                  </v-autocomplete>
                                </v-flex>
                                <!-- <v-flex
                                  xs12
                                  sm4
                                  md4
                                  lg4
                                  :pl-lg-2="divisioncheck ? true : false"
                                  :pl-md-2="divisioncheck ? true : false"
                                  :pl-sm-2="divisioncheck ? true : false"
                                  v-if="divisioncheck"
                                >
                                  <span class="subheading">RRT</span>
                                  <v-autocomplete
                                    outlined
                                    label="RRT Name"
                                    :items="rrtList"
                                    v-model="rrtname"
                                    item-text="name"
                                    item-value="_id"
                                    class="itemform"
                                    hide-details="true"
                                    :disabled="rangename ? false : true"
                                    dense
                                  >
                                  </v-autocomplete>
                                </v-flex> -->
                              </v-layout>
                              <v-layout
                                wrap
                                justify-start
                                v-if="
                                  timeperiod ||
                                  bigsnakes ||
                                  allsnakes ||
                                  users ||
                                  divisioncheck
                                "
                                pt-4
                              >
                                <v-flex xs6 md2 sm2 lg1>
                                  <v-btn
                                    color="success"
                                    depressed
                                    @click="find()"
                                    :disabled="alldata"
                                  >
                                    <span>Submit</span>
                                  </v-btn>
                                </v-flex>
                                <v-flex xs6 md2 sm2 lg1>
                                  <v-btn
                                    color="warning"
                                    depressed
                                    @click="reset()"
                                    :disabled="alldata"
                                  >
                                    <span>Reset</span>
                                  </v-btn>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <v-layout wrap justify-center>
                                <v-flex xs12>
                                  <div id="map"></div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
    <script src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBpukNl1L1qGqEwad87A1-MkZj8H_bKDZM&libraries=visualization,drawing"></script>
    <script>
var marker;
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      data: [],
      map: "",
      mapData: {},
      location: [],
      items: [],
      snakeitems: [],
      userslist: [],
      // checkbox: false,
      timeperiod: false,
      bigsnakes: false,
      allsnakes: false,
      totalcount: 0,
      users: false,
      activePicker: null,
      fromdate: null,
      menu: false,
      divisioncheck: false,
      activePickerto: null,
      todate: null,
      menu2: false,
      bigsnakeselect: "",
      bigsnakestypes: [
        {
          text: "spectacled cobra",
          value: "5c98a6f9db9c5d619e5a721c",
        },
        {
          text: "common krait",
          value: "5c98a7f9db9c5d619e5a721d",
        },
        {
          text: "russell's viper",
          value: "5c988da5db9c5d619e5a7218",
        },
        {
          text: "saw scaled viper",
          value: "5c988c39db9c5d619e5a7217",
        },
      ],
      allsnakelist: "",
      selecteduser: "",
      alldata: true,
      divisionname: "",
      divisionList: [],
      rangeList: [],
      rangename: "",
      rrtname: "",
      rrtList: [],
    };
  },
  beforeMount() {
    this.getData();
    this.getList();
    this.getDivisionData();
    this.find();
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menu2(val) {
      val && setTimeout(() => (this.activePickerto = "YEAR"));
    },
    divisionname() {
      this.getRangeData();
    },
    rangename() {
      this.getRRTData();
    },
  },
  methods: {
    save(fromdate) {
      this.$refs.menu.save(fromdate);
    },
    getDivisionData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/platform/division/getlistdropdown",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.divisionList = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getRangeData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/platform/range/getlistdropdown",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          division: this.divisionname,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.rangeList = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getRRTData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/rescuer/rrt/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          division: this.divisionname,
          range: this.rangename,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.rrtList = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    checkUpdate() {
      if (this.alldata == true) {
        this.timeperiod = false;
        this.bigsnakes = false;
        this.allsnakes = false;
        this.divisioncheck = false;
        this.users = false;
        this.fromdate = null;
        this.todate = null;
        this.divisionname = null;
        this.rangename = null;
        this.rrtname = null;
        this.bigsnakeselect = [];
        this.allsnakelist = [];
        this.selecteduser = "";
        this.find();
      }
    },
    reset() {
      // Reset all checkboxes
      this.timeperiod = false;
      this.bigsnakes = false;
      this.allsnakes = false;
      this.users = false;

      // Reset date pickers
      this.fromdate = null;
      this.todate = null;

      // Reset big snake selection
      this.bigsnakeselect = [];

      // Reset all snake list
      this.allsnakelist = [];

      // Reset user selection
      this.selecteduser = "";

      // You can also trigger any other actions if needed
      // this.msg = "Fields have been reset";
      // this.showSnackBar = true;
    },

    saveto(todate) {
      this.$refs.menu2.save(todate);
    },
    initMap() {
      const vm = this;
      const mapCanvas = document.getElementById("map");

      // Set default map options
      const mapOptions = {
        zoom: 9,
        center: new google.maps.LatLng(9.082, 76.271),
        mapTypeId: google.maps.MapTypeId.TERRAIN,
      };

      // Create a new map
      vm.map = new google.maps.Map(mapCanvas, mapOptions);

      // Check if location data is available
      if (vm.data && vm.data.length > 0) {
        vm.data.forEach((user) => {
          if (user.location && user.location.length === 2) {
            const lat = user.location[1]; // Latitude
            const lon = user.location[0]; // Longitude

            // Create a marker at the location
            const marker = new google.maps.Marker({
              map: vm.map,
              position: new google.maps.LatLng(lat, lon),
              icon: {
                url: require("@/assets/images/snakeicon.png"), // Change the URL for a custom marker
                scaledSize: new google.maps.Size(20, 20), // Adjust the size of the icon
              },
            });

            // Create an InfoWindow with user details

            const infoContent = `
  <div style="font-size: 14px;width: 250px; height: auto;padding: 15px;padding-left: 3px;">
    <span><img 
              src="${
                user.pic ? vm.mediaURL + user.pic : "default-image-url.jpg"
              }" 
              alt="Snake Image" 
              style="width: 100%; height: 100%; object-fit: contain; margin-top: 8px; cursor: pointer;"
            /></span></br>
    <span style="font-size: 13px;font-family: sofiaProMedium;"><strong>Situation:</strong> <b>${
      user.situation || "N/A"
    }</b></span></br>
    <span style="font-size: 13px;font-family: sofiaProMedium;"><strong>Snake Name:</strong> <b>${
      user.snake?.name || "N/A"
    }</b></span> </br>
    <span></span>
  </div>
`;

            const infowindow = new google.maps.InfoWindow({
              content: infoContent,
            });

            // Add a click event listener to the marker to open the InfoWindow
            marker.addListener("click", function () {
              infowindow.open(vm.map, marker);
            });
          }
        });
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/user/rescued/all/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.userslist = response.data.data;
              this.appLoading = false;
              // this.msg = response.data.msg;
              // this.showSnackBar = true;
              this.initMap();
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    find() {
      if (this.fromdate) {
        this.selectedfromdate = this.fromdate;
      }
      if (this.todate) {
        this.selectedtodate = this.todate;
      }
      if (this.bigsnakeselect.length > 0) {
        this.big4snake = this.bigsnakeselect;
      }
      if (this.allsnakelist.length > 0) {
        this.selectedallsnakelist = this.allsnakelist;
      }
      if (this.selecteduser) {
        this.selectedusername = this.selecteduser;
      }
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/location/find",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          from: this.selectedfromdate,
          to: this.selectedtodate,
          big4: this.big4snake,
          snakeid: this.selectedallsnakelist,
          username: this.selectedusername,
          division: this.divisionname,
          range: this.rangename,
          rrt: this.rrtname,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.data = response.data.data.records;
              this.totalcount = this.data.length;
              // this.msg = response.data.msg;
              // this.showSnackBar = true;
              this.initMap();
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    formatDate(date) {
      if (!date) return "-";
      const [year, month, day] = date.slice(0, 10).split("-");
      return `${day}-${month}-${year}`;
    },
    getList() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/snake/all/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.snakeitems = response.data.data;
              this.appLoading = false;
              // this.msg = response.data.msg;
              // this.showSnackBar = true;
              this.initMap();
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
  },
};
</script>
          <style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}
.subheading {
  font-family: poppinsregular;
  font-size: 15px;
  font-weight: 500;
}
#map {
  height: 700px !important;
  width: 100%;
}
</style>